import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { PlaylistRecommendationsModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';

import PlaylistModal from './PlaylistModal';

const RecommendedPlaylists = ({
  playlistRecommendations,
  chosenPlaylists,
  handleUpdatePlaylist,
}: {
  playlistRecommendations: PlaylistRecommendationsModel;
  chosenPlaylists: SpotifyChosenPlaylistsModel[];
  handleUpdatePlaylist: (playlist: SpotifyChosenPlaylistsModel) => void;
}) => {
  const { t } = useTranslation();
  const [activePlaylistId, setActivePlaylistId] = useState<string>();
  const [showPlaylistModal, setShowPlaylistModal] = useState<boolean>(false);

  const activePlaylist = useMemo(() => {
    if (!playlistRecommendations) return;

    return playlistRecommendations.recommendations.find((item) => item.id === activePlaylistId);
  }, [activePlaylistId, playlistRecommendations]);

  return (
    <>
      {activePlaylist && (
        <PlaylistModal
          open={showPlaylistModal}
          onClose={() => {
            setActivePlaylistId(undefined);
            setShowPlaylistModal(false);
          }}
          playlist={activePlaylist}
        />
      )}
      <div className="d-flex gap20 flex-item-wrap jc-center mt20-lg-down">
        {playlistRecommendations.recommendations.map((playlist) => (
          <div
            key={playlist.id}
            className={`card flex-w50p playlist-recommendation-card cursor-pointer ${
              chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyPlaylistId) && 'to-do-card'
            }`}
          >
            <div
              onClick={() =>
                handleUpdatePlaylist({
                  spotifyPlaylistId: playlist.spotifyPlaylistId,
                  recommendationSource: 'recommendation',
                  name: playlist.name,
                  curatorName: playlist.curatorDetails.name,
                  followers: playlist.stats.followers,
                  image: playlist.image,
                })
              }
            >
              <div className="d-flex gap16">
                <div>
                  <object className="playlist-image" data={playlist.image} type="image/png">
                    <img className="playlist-image" src={Images.playlistIcon} alt="Fallback playlist img" />
                  </object>
                </div>
                <div className="text-left mt-auto mb-auto">
                  <p>{playlist.name}</p>
                  <p className="text-faded small capitalize">
                    {playlist.genres.map((item, index) => `${item}${index < playlist.genres.length - 1 ? ', ' : ''}`)} -
                    by {playlist.curatorDetails.name}
                  </p>
                </div>
                <div className="ml-auto">
                  {chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyPlaylistId) ? (
                    <div>
                      <Icon className="text-blue">check_circle</Icon>
                    </div>
                  ) : (
                    <div>
                      <Icon className="material-symbols-outlined text-faded">circle</Icon>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex mt20">
                <div className="flex-grow">
                  <p>{numberFormatter(playlist.stats.followers)}</p>
                  <p className="small text-faded">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</p>
                </div>
                <div className="flex-grow w30p">
                  <p>{playlist.stats.averageTrackPopularity}</p>
                  <p className="small text-faded">{t('PLAYLISTING.AVG-SONG-POPULARITY')}</p>
                </div>
                <div className="flex-grow">
                  <p>{playlist.stats.trackCount}</p>
                  <p className="small text-faded">{t('PLAYLISTING.SONGS')}</p>
                </div>
              </div>
              {playlist.similarArtists.length > 0 && (
                <div className="mt20 text-left mb48">
                  <p className="small">{t('PLAYLISTING.SIMILAR-ARTISTS')}</p>
                  <div className="d-flex mt8">
                    <div className="d-flex">
                      {playlist.similarArtists.map((item, index: number) => (
                        <img
                          key={index}
                          className={`similar-artist ${index > 0 ? 'ml-8' : ''}`}
                          src={item.image}
                          alt=""
                        />
                      ))}
                    </div>
                    <div className="pl8 mt-auto mb-auto">
                      <p className="text-faded small pt0">
                        Featuring{' '}
                        {playlist.similarArtists.map(
                          (item, index: number) =>
                            `${item.name}${index < playlist.similarArtists.length - 1 ? ', ' : ''}`
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="footer-button">
              <Button
                className="btn-black-2 w100p ml0"
                onClick={() => {
                  setActivePlaylistId(playlist.id);
                  setShowPlaylistModal(true);
                }}
              >
                {t('PLAYLISTING.VIEW-DETAILS')}
              </Button>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RecommendedPlaylists;
