import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { PlaylistPreviousPitchesAPIModel } from '@/models/Playlist';
import { SpotifyChosenPlaylistsModel } from '@/models/Spotify';

const PreviousPlaylistPitches = ({
  previousPlaylistPitches,
  chosenPlaylists,
  handleUpdatePlaylist,
}: {
  previousPlaylistPitches: PlaylistPreviousPitchesAPIModel;
  chosenPlaylists: SpotifyChosenPlaylistsModel[];
  handleUpdatePlaylist: (playlist: SpotifyChosenPlaylistsModel) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex gap20 flex-item-wrap jc-center mt20-lg-down">
        {previousPlaylistPitches.items.map((playlist) => (
          <div
            key={playlist.id}
            className={`card flex-w50p playlist-recommendation-card previous-playlists cursor-pointer ${
              chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyId) && 'to-do-card'
            }`}
          >
            <div
              onClick={() =>
                handleUpdatePlaylist({
                  spotifyPlaylistId: playlist.spotifyId,
                  recommendationSource: 'previous-success',
                  name: playlist.name,
                  curatorName: playlist.curatorDetails.name,
                  followers: playlist.stats.followers,
                  image: playlist.image,
                })
              }
            >
              <div className="d-flex gap16">
                <div>
                  <object className="playlist-image" data={playlist.image} type="image/png">
                    <img className="playlist-image" src={Images.playlistIcon} alt="Fallback playlist img" />
                  </object>
                </div>
                <div className="text-left mt-auto mb-auto">
                  <p>{playlist.name}</p>
                  <p className="text-faded small capitalize">
                    {playlist.genres.map((item, index) => `${item}${index < playlist.genres.length - 1 ? ', ' : ''}`)} -
                    by {playlist.curatorDetails.name}
                  </p>
                </div>
                <div className="ml-auto">
                  {chosenPlaylists.map((item) => item.spotifyPlaylistId).includes(playlist.spotifyId) ? (
                    <div>
                      <Icon className="text-blue">check_circle</Icon>
                    </div>
                  ) : (
                    <div>
                      <Icon className="material-symbols-outlined text-faded">circle</Icon>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex mt20 gap10">
                <div className="card-inner">
                  <p>{numberFormatter(playlist.stats.followers)}</p>
                  <p className="small text-faded">{t('INSIGHTS-PAGE.COMMON.FOLLOWERS')}</p>
                </div>
                <div className="flex-grow d-flex card-success gap10">
                  <h3 className="mt-auto mb-auto">🥳</h3>
                  <p className="small text-left mt-auto mb-auto">
                    {t('PLAYLISTING.YOU-HAVE-SUCCESSFULLY-PITCHED-BEFORE')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PreviousPlaylistPitches;
